import React from "react"
import type Node from "react";
import tw from "tailwind-styled-components";
import { DocumentIcon, HeartIcon, CodeIcon } from '@heroicons/react/outline'
import { Anchor, Logo } from "../components";

// TO DO: Automate
const supportLinks = [
  {
    name: 'On Boarding',
    to: '/onboarding',
    anchorText: 'View Docs',
    description: 'Welcome to Penelope. Resources to get your started building an awesome product.',
    icon: HeartIcon,
  },
  {
    name: 'Engineering',
    to: '/engineering',
    anchorText: 'View Docs',
    description: 'Platform documentation for architects and engineers.',
    icon: CodeIcon,
  },
  {
    name: 'Security',
    to: '/security',
    anchorText: 'View Docs',
    description: 'Familiarize yourself with all Penelope security policies, procedures and  documentation.',
    icon: DocumentIcon,
  },
]

export default (): Node => {
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-48 bg-gray-800">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <Logo
            darkMode={true}
            size={"large"}
          />
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            All documentation, policies, and procedures in one place - versioned by GitHub.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-pink-500 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon className="h-8 w-8 text-white" aria-hidden="true" />
                </div>
                <Title>
                  {link.name}
                </Title>
                <Description>
                  {link.description}
                </Description>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <Anchor
                  to={link.to}
                  className="uppercase font-bold border border-gray-400 rounded-md hover:bg-gray-100 hover:border-gray-500 p-2 "
                >
                  {link.anchorText}
                </Anchor>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

const Title = tw.h3`
  font-bold
  text-2xl  
  text-gray-900
`

const Description = tw.p`
  font-medium
  mt-3
  text-gray-600
  text-lg
`
